import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button, Container } from "reactstrap";

import OptionsFrameHOC from '../../component/Forms/OptionsFrameHOC';


const Appointments = () => {
    const navigate = useNavigate()


    const handleLetters = () => {
        alert('Letters Clicked');
    };

    const handleList = () => {
        alert('Lists Clicked');
    };

    const handleExit = () => {
        navigate('/dashboard/home')
    };

    const today = new Date();
    const months = [];

    for (let i = 0; i < 6; i++) {
        const newDate = new Date(today.getFullYear(), 5 + i, 1); // 5 corresponds to June (0-indexed)
        months.push(newDate);
    };

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });


    return (
        <OptionsFrameHOC
            title="Appointment Lists"
            titleSize="lg"
        >
            <div className="calendar-grid">
                {months.map((month, index) => (
                    <div key={index} className="calendar-container">
                        <Calendar value={month} />
                    </div>
                ))}
                <span>Today: {" "}{formattedDate}</span>
            </div>
            <div className="calendar-lower">

            </div>
            <div className="text-xs-center mt-3 fR mb-3">
                <Button className="btn btnIri" onClick={handleLetters}>Letters</Button>
                <Button className="btn btnIri marRight20" onClick={handleList}>List</Button>
                <Button type="submit" className="btn btnIri marRight20" onClick={handleExit}>Exit</Button>
            </div>
        </OptionsFrameHOC>
    )
}

export default Appointments;

