import React, { useState, useEffect } from 'react';
import FileManager, { Permissions } from 'devextreme-react/file-manager';
import { Popup } from 'devextreme-react/popup';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import { useDispatch } from 'react-redux'
import { createRecord, deleteRecord, fileUpload, getAllRecords } from 'redux/actions/file.action.js';
import { LoadPanel } from 'devextreme-react/load-panel';
import { env_vars } from 'Utils/config';

const allowedFileExtensions = [];
const loadPanelPosition = { of: '#file-manager' };


function App() {
  const dispatch = useDispatch()

  const [state, setState] = useState({
    popupVisible: false,
    popupType: 'img',
    imageItemToDisplay: {},
  })

  const [files, setFiles] = useState([])


  async function getItems(parentDirectory) {
    let { dataItem } = parentDirectory
    let items = await dispatch(getAllRecords(dataItem ? dataItem.id : null));

    return items
  }


  function createDirectory(parentDirectory, name) {
    let { dataItem } = parentDirectory
    dispatch(createRecord({ name, isDirectory: true }, dataItem ? dataItem.id : null))
  }



  function displayImagePopup(e) {

    console.log(e)








    setState({
      ...state,
      popupVisible: true,
      imageItemToDisplay: {
        name: e.file.name,
        url: e.file.dataItem.fileUrl,
      },
    });
  }

  function hideImagePopup() {
    setState({
      ...state,
      popupVisible: false,
    });
  }

  function onCurrentDirectoryChanged(e) {
    // setState({...state, currentPath: e.component.option('currentPath')})

  }

  function handleUpload(e) {
    let parent = e.destinationDirectory.dataItem ? e.destinationDirectory.dataItem.id : null

    const formData = new FormData();
    formData.append('file', e.fileData);

    dispatch(fileUpload(formData, parent))
      .then(() => {
        getItems(e.destinationDirectory)
      })
      .catch(err => {
        console.log(err)
      })

  }




  function handleFiles() {
    dispatch(getAllRecords())
      .then(a => {
        setFiles(a)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function downloadItems(items) {
    for (let item of items) {
      let { id, fileUrl } = item && item.dataItem ? item.dataItem : {};
      //  dispatch(getFile(_id))
      window.open(env_vars.api_url + `/files/download/${id}`, '_blank')
    }
    // azure.downloadFile(items[0].path);
  }


  function deleteItem(item) {
    dispatch(deleteRecord(item.key))
  }


  useEffect(() => {
    handleFiles()
  }, [])




  const fileSystemProvider = new CustomFileSystemProvider({
    getItems,
    createDirectory,
    renameItem,
    deleteItem,
    copyItem,
    moveItem,
    uploadFileChunk,
    downloadItems,
  });

  return (
    <div style={{ width: '100%' }}>
      <LoadPanel visible={state.loadPanelVisible} position={loadPanelPosition} />

      <FileManager
        // currentPath={state.currentPath}
        id="file-manager"

        fileSystemProvider={fileSystemProvider} allowedFileExtensions={allowedFileExtensions}
        onSelectedFileOpened={displayImagePopup}
        onCurrentDirectoryChanged={onCurrentDirectoryChanged}
        onFileUploading={handleUpload}
      >
        <Permissions
          create={true}
          copy={true}
          move={true}
          delete={true}
          rename={true}
          upload={true}
          download={true}>
        </Permissions>
      </FileManager>

      <Popup
        // maxHeight={600}
        hideOnOutsideClick={true}
        title={state.imageItemToDisplay.name}
        visible={state.popupVisible}
        onHiding={hideImagePopup}
        className="photo-popup-content">

        <img width="100%" height="100%" src={state.imageItemToDisplay.url} className="photo-popup-image" />
      </Popup>
      <small style={{ textAlign: 'right' }} onClick={() => { localStorage.clear(); window.location.href = '/signin' }}>Logout</small>

    </div>
  );
}






function renameItem(item, name) {
  console.log(item)
  console.log(name)
}


function copyItem(item, destinationDirectory) {
  console.log(item)
  console.log(destinationDirectory)
}

function moveItem(item, destinationDirectory) {
  console.log(item)
  console.log(destinationDirectory)
}

function uploadFileChunk(fileData, uploadInfo, destinationDirectory) {

  console.log(fileData)
  console.log(uploadInfo)
  console.log(destinationDirectory)

  // let promise = null;

  // if (uploadInfo.chunkIndex === 0) {
  //   const filePath = destinationDirectory.path ? `${destinationDirectory.path}/${fileData.name}` : fileData.name;
  //   promise = gateway.getUploadAccessUrl(filePath).then((accessUrls) => {
  //     uploadInfo.customData.accessUrl = accessUrls.url1;
  //   });
  // } else {
  //   promise = Promise.resolve();
  // }

  // promise = promise.then(() => gateway.putBlock(
  //   uploadInfo.customData.accessUrl,
  //   uploadInfo.chunkIndex,
  //   uploadInfo.chunkBlob,
  // ));

  // if (uploadInfo.chunkIndex === uploadInfo.chunkCount - 1) {
  //   promise = promise.then(() => gateway.putBlockList(
  //     uploadInfo.customData.accessUrl,
  //     uploadInfo.chunkCount,
  //   ));
  // }

  // return promise;
}



export default App;
