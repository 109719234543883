import React from "react";
import { Col, Row, Button, Container } from "reactstrap";
import OptionsFrameHOC from "../../component/Forms/OptionsFrameHOC";
import { useNavigate } from 'react-router-dom';

const data = [
  {
    title: 'No biopsy Result after 14 days',
    items: [
      { date: '18/04/23', name: 'Jane Doe', code: '(MDT13)' },
      { date: '18/04/23', name: 'Jane Doe', code: '(MDT13)' },
      { date: '18/04/23', name: 'Jane Doe', code: '(MDT13)' },
      { date: '18/04/23', name: 'John Doe', code: '(MDT14)' },
    ],
  },
  {
    title: 'No treatment 6 weeks after CIN biopsy',
    items: [
      { date: '', name: '', code: '' },
    ],
  },
  {
    title: 'No smear Result after 4 weeks',
    items: [
      { date: '19/07/23', name: 'Asdasd Asdasdda', code: '(X1)', text: 'Timesadasdsds' }
    ],
  },
  {
    title: 'Late GA Treatment',
    items: [
      { date: '', name: '', code: '' },
    ],
  },
  {
    title: 'No swab results after 14 days',
    items: [
      { date: '28/08/23', name: 'Steven Alba', code: '(XB1)' },
      { date: '29/08/23', name: 'Steven Alba', code: '(XB1)' },
      { date: '30/08/23', name: 'Steven Alba', code: '(XB1)' },
      { date: '18/04/23', name: 'Jane Doe', code: '(MDT13)' },
    ],
  },
  {
    title: 'DNA not rescheduled',
    items: [
      { date: '', name: '', code: '' },
    ],
  },
];

const Failsafe = () => {
  const navigate = useNavigate()


  const handleReview = () => {
    alert('Review Clicked');
    console.log('Review Clicked');
  };

  const handlePrint = () => {
    alert('Print Clicked');
    console.log('Print Clicked');
  };

  const handleExit = () => {
    console.log('Exiting Now');
    navigate('/dashboard/home')
  };


  return (
    <OptionsFrameHOC
      title="Failsafe Lists"
      titleSize="lg"
    >
      <Container>
        <Row className="rows">
          {data.map((item, index) => (
            <Col key={index} xs={12} md={6} className="" bg-primary p-2>
              <span className="title-text">{item.title}</span>
              <div className="box">
                {item.items.map((subItem, subIndex) => (
                  <div className="box-content" key={subIndex}>
                    <p>{subItem.date} {subItem.name}</p>
                    <p>{subItem.code}</p>
                    <p>{subItem.text}</p>
                  </div>
                ))}
              </div>
            </Col>
          ))}
        </Row>
        <div className="text-xs-center mt-2 fR mb-3">
          <Button className="btn btnIri" onClick={handleReview}>Review</Button>
          <Button className="btn btnIri marRight20" onClick={handlePrint}>Print</Button>
          <Button type="submit" className="btn btnIri marRight20" onClick={handleExit}>Exit</Button>
        </div>
      </Container>
    </OptionsFrameHOC>
  )
};

export default Failsafe;

